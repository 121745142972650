import './App.css';
import './grid-container.css';
import React, { useState } from "react";

const jiraToken = process.env.REACT_APP_JIRA_TOKEN;

const escalatedURL = process.env.REACT_APP_ESCALATED_URL;
const notUpdatedURL = process.env.REACT_APP_NOTUPDATED_URL;
const highPrioURL = process.env.REACT_APP_HIGHPRIO_URL;
const breachedSLAURL = process.env.REACT_APP_BREACHEDSLA_URL;
const approachBreachURL = process.env.REACT_APP_APPROACHBREACH_URL;

const triageJQL = process.env.REACT_APP_TRIAGE_JQL;
const leaversJQL = process.env.REACT_APP_LEAVERS_JQL
const startersJQL = process.env.REACT_APP_STARTERS_JQL
const JQLBaseURL = process.env.REACT_APP_JQL_BASEURL

const goodColour = "#3dff2b"
const breachColour = "#ff2b2b"
const approachBreachColour = "#ff9c2b"
const textColour = "#ffffff"




function App() {

  async function fetchQueueSize(URL) {
    const request = await fetch(
      URL, {headers: {"Authorization": jiraToken}}
    )
    const obj = await request.json()
    var queueSize = obj.size
    if (!obj.isLastPage) {
      queueSize += await fetchQueueSize(obj._links.next)
    }
    return queueSize
  }

  async function updateQueue(URL, breachCount, approachBreachCount, elementName, noColours=false) {
    const result = await fetchQueueSize(URL)
    const docElement = document.getElementById(elementName)
    if (noColours) {
      docElement.style.color = textColour;
    } else if (result >= breachCount) {
      docElement.style.color = breachColour;
    } else if (result < breachCount && result > approachBreachCount) {
      docElement.style.color = approachBreachColour;
    } else if (result <= approachBreachCount) {
      docElement.style.color = goodColour;
    } else {
      docElement.style.color = textColour;
    } 

    docElement.innerHTML = result;
  }

  async function fetchJQLSize(JQL) {
    const request = await fetch(
      JQLBaseURL+JQL, {headers: {"Authorization": jiraToken}}
    )
    const obj = await request.json()
    return obj.total
  }

  async function updateJQL(JQL, breachCount, approachBreachCount, elementName, noColours=false) {
    const result = await fetchJQLSize(JQL)
    const docElement = document.getElementById(elementName)
    if (noColours) {
      docElement.style.color = textColour;
    } else if (result >= breachCount) {
      docElement.style.color = breachColour;
    } else if (result < breachCount && result >= approachBreachCount) {
      docElement.style.color = approachBreachColour;
    } else if (result < approachBreachCount) {
      docElement.style.color = goodColour;
    } else {
      docElement.style.color = textColour;
    }

    docElement.innerHTML = result;
  }


  function requestTriage() {
    updateJQL(triageJQL, 9, 5, "triage")
  }

  function requestEscalated() {
    updateQueue(escalatedURL, 5, 3, "escalated")
  }

  function breachedSLA() {
    updateQueue(breachedSLAURL, 25,  10, "itsmBreached")
  }

  function approachBreachedSLA() {
    updateQueue(approachBreachURL, 2, 0.5, "itsmApproachBreached")
 }

  function requestnotUpdated() {
    updateQueue(notUpdatedURL, 30, 10, "notUpdated")
  }

  function requestStarters() {
    updateJQL(startersJQL, 20, 10, "starters", true)
  }

  function requestLeavers() {
    updateJQL(leaversJQL, 11, 8, "leavers", true)
  }

  function requesthighPrio() {
    updateQueue(highPrioURL, 10, 5, "highPrio")
  }

  function ZhuLiDoTheThing() {
    requestTriage()
    requestEscalated()
    breachedSLA()
    requestnotUpdated()
    approachBreachedSLA()
    requestStarters()
    requestLeavers()
    requesthighPrio()
  }

  const [counter, setCounter] = useState(0)

  setInterval(() => {
    ZhuLiDoTheThing()
  }, 10000)

  console.log(setCounter)
  ZhuLiDoTheThing()

  return (
    <div className="App">
      <header className="App-header">
        <div className="titleDiv">
          <p className="title">JIRA Dashboard</p>
        </div>
        <div className="imageDiv">
          <img src="https://assets-global.website-files.com/624410f4e729c6256c749f12/62442ead917d0862626bfdb3_tc_logo_reversed.svg" className="image" alt="tcLogo" height="50px" width="300px" />
        </div>
      </header>
      <div className="grid-container">
        <div className="queue">
          <div className="queueType">
            <p className="queueName"><b>Queue Type</b></p>
          </div>
          <div className="queueType">
            <p className="queueName">Awaiting Triage</p>
          </div>
          <div className="queueType">
            <p className="queueName">Breached SLA</p>
          </div>
          <div className="queueType">
            <p className="queueName">Escalated</p>
          </div>
          <div className="queueType">
            <p className="queueName">Not Updated for 7 Days</p>
          </div>
          <div className="queueType">
            <p className="queueName">Approaching Breached SLA</p>
          </div>
          <div className="queueType">
            <p className="queueName">New Starters</p>
            <p className="queueDescription">For this week and the following</p>
          </div>
          <div className="queueType">
            <p className="queueName">New Leavers</p>
            <p className="queueDescription">For this week and previously</p>
          </div>
          <div className="queueType">
            <p className="queueName">Open P1/P2/P3</p>
          </div>
        </div>
        <div className="amount-tickets">
          <div className="queueType">
            <p id="amountTitle"><b>Amount of Tickets</b></p>
          </div>
          <div className="queueType">
            <p id="triage">{counter}</p>
          </div>
          <div className="queueType">
            <p id="itsmBreached">{counter}</p>
          </div>
          <div className="queueType">
            <p id="escalated">{counter}</p>
          </div>
          <div className="queueType">
            <p id="notUpdated">{counter}</p>
          </div>
          <div className="queueType">
            <p id="itsmApproachBreached">{counter}</p>
          </div>
          <div className="queueType">
            <p id="starters">{counter}</p>
          </div>
          <div className="queueType">
            <p id="leavers">{counter}</p>
          </div>
          <div className="queueType">
            <p id="highPrio">{counter}</p>
          </div>
        </div>
        {/* <div className="breached">
          <div className="queueType">
            <p id="amountBreached">Amount of Breached SLA</p>
          </div>
          <div className="queueType">
          </div>
          <div className="queueType">
          </div>
          <div className="queueType">
          </div>
          <div className="queueType">
          </div>
          <div className="queueType">
          </div>
          <div className="queueType">
          </div>
        </div> */}
      </div>
    </div>
  );
}

// App.use(
//   promMid({
//     metricsPath: '/metrics',
//   }),
// );

export default App;
