import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {MetricsProvider} from '@cabify/prom-react';

const normalizePath = (path) => {
  const match = path.match(/\/products\/(\d+)/);
  if (match) {
   return `/products/:id`;
  }
 return path;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MetricsProvider appName='jiraDash' owner="Brad" getNormalizedPath={normalizePath} metricsAggregatorUrl="https://some-metrics-aggregator.com/push-metrics">
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </MetricsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
